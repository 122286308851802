<template>
    <div class="container">
      <codemirror :value="code" :options="editorOption" ref="myEditor" class="codemirror-container" @change="yourCodeChangeMethod"/>
      <!-- <codemirror v-model="code" :options="editorOption" ref="myEditor" class="codemirror-container" @change="yourCodeChangeMethod"/> -->
    </div>
  </template>
  
  <script>
  import { codemirror } from 'vue-codemirror-lite'
  
  export default {
    name: 'RunbuildCodeMirror',
    props:['code'],
    components: { codemirror },
    data() {
      return {
        // code:'',
        editorOption:{
          mode: "javascript",
          lineNumbers: true,
          tabSize: 2,
          autofocus:true,
          autocorrect:true,
        }
      };
    },
    computed: {
      editor() {
        return this.$refs.myEditor.editor
      }
    },
    mounted() {
      this.editor.focus() 
        this.editor.setSize("100%","100%")
    },
  
    methods: {
      yourCodeChangeMethod() {
        this.$emit('code',this.$refs.myEditor.editor.getValue());
        // this.code = this.$refs.myEditor.editor.getValue()
      } 
    },
  };
  </script>
  
  <style lang="scss">
  .codemirror-container {
    color: white;
    font-family: 'Fira Code', monospace; 
    text-align: left;
    font-size: 14px;
    height: 800px;
    border: 2px solid lightskyblue;
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  } 
   
  @media (max-width: 500px) {
    .codemirror-container {
    height: 400px;
    } 
  }
  </style>
  