<template>
  <v-container class="font-italic white--text pb-3"> 
    <div class="aiSection">
      <!-- 입력폼 -->
      <v-card-text class="contentBg">
        <v-form ref="form" lazy-validation>
          <v-container> 
            <v-text-field v-model="data.title" label="제목" required :rules="Rules" dark  :counter="50"  ></v-text-field>
            <v-text-field v-model="data.site" label="완성작품" required :rules="Rules" dark  :counter="50"  ></v-text-field>
            <v-text-field v-model="data.youtube" label="제작영상" required :rules="Rules" dark  :counter="50"  ></v-text-field>
            <!-- 썸네일 이미지 -->
                  <!-- <v-file-input accept="image/*" label="사진" id="imginput" @change="addPic"   style="display: none" />
                  <label for="imginput" style="cursor:pointer" >
                    <div class="mx-auto imgBg" :style="`background-image:url(${data.img})`">
                    </div>
                  </label> -->
            <v-textarea v-model="data.content" label="작품설명" required :rules="Rules" dark textarea dense outlined class="pt-7" />
            <v-textarea v-model="data.prompt" label="프롬프트" required :rules="Rules" dark textarea dense outlined  />
            <v-textarea v-model="data.code" label="완성코드" required :rules="Rules" dark textarea dense outlined  />
          </v-container>
        </v-form>
        <v-divider dark color="var(--main-color)"></v-divider>
        <!-- 저장버튼 --> 
        <v-btn :loading="loading" color="var(--main-color)" block depressed @click="save" > <h2>SAVE</h2> </v-btn>
      </v-card-text>
      <!-- </v-card> -->
    </div>
  </v-container>
</template>

<script>
export default {
  name: "AdminInput",
  data() {
    return {
      data: {
        id: "",
        uid:this.$store.state.userData?this.$store.state.userData.uid:'',
        creator: this.$store.state.userData?this.$store.state.userData.name:'',
        team: "어벤져스",
        title: "",
        site: "",
        content: "",
        prompt: "",
        youtube: "",
        time: 60,
        type: "workout",
        img: "https://images.unsplash.com/photo-1611162616475-46b635cb6868?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
        code:"",
        aiSrc: "",
        fileName1:'',
        today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10),
        endDate: "",
        password: "",
        people: 7,
        // img: "https://cdn.vuetifyjs.com/images/cards/cooking.png",
        rating: 3.5,
        maxAdd:'max',
        members: [],
      },
      members: [1115, "member"],
      file1: "https://cdn.vuetifyjs.com/images/cards/cooking.png",
      imgsrc: "",
      loading: false,
      Rules: [(v) => !!v || "필수입력란"],
    };
  },

  mounted() {},
  created() {
    // this.getData();
  },
  methods: {
    getData() {
      if (this.$route.params.id) {this.data = this.$route.params;}
    }, 

    async addPic(e) {
    if (e instanceof Blob) {
             const reader = new FileReader();
             reader.readAsDataURL(e);
             reader.onload = () => {
             this.file1 = e;
             this.data.img = reader.result;
           };
         } else {
         console.error("Invalid file or blob object.");
       }
    },

   async save() { 
      // 빈칸 검사
      const valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      this.loading = true;
      this.saveAirtable();
      //이미지 업로드
      // if(this.file1.name){
      //   const sn = await this.$firebase.storage().ref().child('youtube/' + this.file1.size).put(this.file1)
      //   this.data.img = await sn.ref.getDownloadURL()
      //   console.log(this.data.img)
      // }else{
      //   return
      // } 

    }, 

    //에어테이블 저장
    async saveAirtable(){
      const videoId = this.data.youtube.substring(this.data.youtube.lastIndexOf('/') + 1);
      const img = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
      const id = Date.now().toString(); 
      const API_KEY = process.env.VUE_APP_AIRTABLE
      const airtableUrl = 'https://api.airtable.com/v0/appi1g0DJSemiNpki/youtube';
      const headers = {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json'
      };
      const maxLength = 100000; // Airtable에 저장할 수 있는 최대 문자 길이 
      const data = {
        "fields": {
          "id": id,
          "uid":this.data.uid,
          "creator":this.data.creator,
          "date": this.data.today,
          "title": this.data.title,
          "site":this.data.site,
          "content": this.data.content,
          "prompt":this.data.prompt,
          "youtube":this.data.youtube,
          "img":img,
          // "img":this.data.img,
          "code":this.data.code,
        }
      };
      console.log(data)
      try {
        const response = await fetch(airtableUrl, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(data)
        });
        const json = await response.json();
      this.loading = false;
      this.data.uid = ""
      this.data.creator = ""
      this.data.today = ""
      this.data.title = ""
      this.data.aiSrc = ""
      this.data.site = ""
      this.data.content = ""
      this.data.prompt = ""
      this.data.youtube = ""
      this.data.img = ""
      this.data.code = ""
      this.data.img="https://images.unsplash.com/photo-1611162616475-46b635cb6868?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
        console.log('Successfully saved to Airtable!');
      this.loading = false;

      } catch (error) {
        console.error(error);
      }
    }
  
    

  },
};
</script>

<style lang="scss" scoped>
v-data-table {
  flex-direction: row;
}
.rankTop {
  background-color: #272727;
  border-bottom: 3px solid var(--second-color); 
}
.cardRank {
  overflow: auto;
  background-color: #595959;

  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.aiSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  overflow: hidden;
  background-image: url(@/assets/bg.png);

  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.topBar {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  max-height: 300px;
  border-bottom: solid 3px var(--main-color);
}
.cameraInfo {
  border-top: solid 5px #ccf863;
}
.contentBg {
  
  background-color: rgba(1, 1, 1,0.6);
}
.imgBg{
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
