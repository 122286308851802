const manifest = `{
  "name": "Myapp",
  "short_name": "MyApp",
  "description": "This is a colorful fun web app for you",
  "dir": "ltr",
  "display": "standalone",
  "orientation": "any",
  "scope": "/",
  "start_url": "/",
  "theme_color": "#ffffff",
  "background_color": "#ffffff",
  "categories": ["life"],
  "screenshots": [],
  "icons": [{
		"src": "/icon-512x512.png",
		"sizes": "512x512"
	}],
  "shortcuts": []
}`

export default manifest
