<template>
  <v-container class="pb-15" style="height: 100%">
    <!-- <div class="d-flex login pr-5" style="height:45px"> 
        <v-progress-circular indeterminate color="var(--main-color)"  v-if="$store.state.fireUser&&!$store.state.userData"  />
        <v-speed-dial v-model="fab"    direction="right"  v-if="$store.state.fireUser&&$store.state.userData"  >
            <template v-slot:activator> 
              <v-btn v-model="fab" small color="transparent" dark fab style="transform: translateX(10px);" >
                <Avataaars :width="50" :height="50" :avatarOptions="$store.state.userData.options"  />
              </v-btn>
            </template> 

        </v-speed-dial>
        <v-btn small rounded color="var(--second-color)"   style="transform: translateY(10px);" @click="dialogLogin = true" v-if="!$store.state.fireUser"  >
          <h3>LOGIN</h3>
        </v-btn>
        <v-btn small rounded color="var(--bar-color)" style="transform: translate(20px, 10px);color:white" @click="logout" v-else >
              <h3>LOGOUT</h3>
        </v-btn> 
        <v-spacer></v-spacer>
    </div> 
    <v-dialog v-model="dialogLogin" max-width="500px">
      <DialogLogin @close="dialogLogin = false" />
    </v-dialog>  -->

    <v-card class="mt-9 mb-5"  > 
      <div color="cyan" dark flat class="bg"   >  
           <div class="pa-5" style="flex: 2; z-index: 2">
              <div class="d-flex justify-center" style="position: relative;">
                <!-- <div class="logo"></div> -->
                <div >
                  <h1 style="font-size: var(--big-size);color: white;  text-shadow: 2px 2px 2px gray;"> Run Build </h1>
                  <h3 class="smallText mt-1" style="color: white;  text-shadow: 2px 2px 2px gray;">Run your code and build app</h3>
                </div>
                <img class="people jumboRight ml-2" :src="bgImg" style="z-index: 1; width: 150px;" />
              </div>
            </div>
      </div>
    </v-card>
  
    <v-card class="elevation-5" >
    <div color="cyan" dark flat class="toolbar"  >  
      <!-- <div>    
        <h2 class="font-italic text-left pa-4" style="color: aliceblue;">What will you build?</h2>      
        <img class="jumboRight robot" :src="bgImg" style="z-index: 1" />
      </div> -->
        <!-- <div class="youtubeCard pa-3 "  >
          <v-card v-for="(n,i) in items" :key="i" color="#26c6da" @click="itemSelect(n);code=n.content;tab=0;btnTitle='CONTENT'" dark width="150" max-height="100" class="mr-2" >
            <v-img height="100" :src=n.img > 
            </v-img>
          </v-card>
        </div> -->

        <!-- <v-expand-transition >
          <div   style="width: 100%;" > 
            <div v-show="showTF"  >
              <v-card class="ma-4 pa-3" style="background: rgba(0,0,0,0.3);position: relative;">                
                <v-card-text class="d-flex justify-space-between" >
                <h1 class="text-center mx-auto" style="color:white"  >{{ this.cards.title }}</h1>
                <v-btn  class="ml-auto"   @click="showTF=false"  text style="position: absolute;top: 0;right: 0;"   >
                  <h4 style="color:white;">✖</h4>
                </v-btn>   
              </v-card-text>
                <v-card-text class="d-flex justify-space-between" style="flex-wrap: wrap;" >
                  <v-btn class="mt-2 " rounded dark block color="#1576c0" @click="opensite(cards.site)">🚀완성작품</v-btn>
                  <v-btn class="mt-2 " rounded dark block color="#1576c0" @click="opensite(cards.youtube)">📺제작영상</v-btn>
                  <v-btn class="mt-2 " rounded dark block color="#1576c0" @click="code = cards.prompt;btnTitle='PROMPT'">💬프롬프트</v-btn>
                  <v-btn class="mt-2 " rounded dark block color="#1576c0" @click="code = cards.code;btnTitle='CODE'"   >📃전체코드</v-btn>
              </v-card-text>
            </v-card>              
            </div>
            </div>
          </v-expand-transition> -->
        
        <v-tabs v-model="tab" class="ml-3" color="white" background-color="transparent" >
          <v-tabs-slider color="yellow"></v-tabs-slider> 
          <v-tab @click="showtab()" > {{btnTitle}} </v-tab>
          <v-tab > Manifest </v-tab>
          <v-tab  > Service</v-tab>
          <v-tab v-if="$store.state.fireUser&&$store.state.admin" @click="showTF=false;showtab()"> Admin </v-tab>
        </v-tabs>
    </div>
    <v-tabs-items v-model="tab" class="itemTab " >
      <v-tab-item  >
        <CodeMirror :code="code" @code="viewCode"/>
        <v-row class="mx-3">
          <v-btn class="mb-7 mt-2" color="var(--main-color)" rounded @click="codeGen();" :disabled="code===''" >Run</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="mb-7 mt-2" color="var(--main-color)" rounded @click="addLinks();" :disabled="code===''" >PWA</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="mb-7 mt-2" color="var(--main-color)" rounded @click="downloadFile(code)" :disabled="code===''" ><v-icon>mdi-download</v-icon> </v-btn>
        </v-row>
      </v-tab-item>
      <v-tab-item  >
        <CodeMirror :code="mani" @code="viewManifest"/>
        <v-row class="mx-3">
          <v-spacer></v-spacer>
          <v-btn class="mb-7 mt-2" color="var(--main-color)" rounded @click="downloadFile(mani)"  ><v-icon>mdi-download</v-icon> </v-btn>
        </v-row>
      </v-tab-item> 
      <v-tab-item  >
        <CodeMirror :code="service" @code="viewService"/>
        <v-row class="mx-3">
          <v-spacer></v-spacer>
          <v-btn class="mb-7 mt-2" color="var(--main-color)" rounded @click="downloadFile(service)"  ><v-icon>mdi-download</v-icon> </v-btn>
        </v-row>
      </v-tab-item> 
      <v-tab-item  >
        <AdminInput/>
      </v-tab-item> 
    </v-tabs-items>
  </v-card>
 


 
  </v-container>
</template>

<script>
import DialogLogin from "@/components/DialogLogin.vue";
import Avataaars from "vue-avataaars";
import CodeMirror from "./CodeMirror.vue";
import AdminInput from "./AdminInput.vue";
import manifest from "./manifest.js";
import service from "./serviceWorker.js";

export default {
  name: "HomeView",
  // components: { DialogLogin,Avataaars, CodeMirror,AdminInput },
  components: { CodeMirror,AdminInput },
  data() {
    return {
      btnTitle:'Code',
      bgImg: require("@/assets/robot.png"),
      tab: null,
        text: 'Lorem ipsum dolor sit amet, consecte',
      fab:false,
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10),
        uid:this.$store.state.userData?this.$store.state.userData.uid:'',
        creator: this.$store.state.userData?this.$store.state.userData.name:'',
        email: this.$store.state.userData?this.$store.state.userData.email:'',
      dialogLogin: false,
      loading: false,
      itemsTF:true,
      gameItemsTF:true,
      items: [], 
      gameItems: [],  
      version:'',
      showTF:false,
      cards:{},
      // manifest : JSON.stringify(manifest),
      code:'',
      service:service,
      mani : manifest,
    };
  },
// watch:{
// code(msg){
//   console.log(msg)
// }
// },
  created() {
    // this.getAirtable();
    // console.log(this.maniLink,this.serviceLink)
  },
  methods: {
    showtab(){
      console.log(this.tab)
    },

    //에어테이블 읽기
    async getAirtable() {
      const API_KEY = process.env.VUE_APP_AIRTABLE;
      const airtableUrl = `https://api.airtable.com/v0/appi1g0DJSemiNpki/youtube`;
      const headers = {
        Authorization: `Bearer ${API_KEY}`
      };
      try {
        const response = await fetch(airtableUrl, { headers });
        const json = await response.json();
        this.items = json.records.map(record => ({
          id: record.id,
          creator: record.fields.creator,
          date: record.fields.date,
          title: record.fields.title,
          site: record.fields.site,
          content: record.fields.content,
          prompt: record.fields.prompt,
          youtube: record.fields.youtube,
          img: record.fields.img,
          code : record.fields.code // 좋아요 개수
        }));
         // date 필드값으로 정렬
    this.items.sort((a, b) => new Date(b.date) - new Date(a.date));
    this.items = this.items.slice(0, 5); // 5개까지만 리스트
      } catch (error) {
        console.error(error);
      }
    },
    test(){
      console.log(this.cards)
    },
    itemSelect(n){
      this.cards = n
      this.showTF = true
      if (n.link < 10) { return }
      // window.open(n.link)
  },  
    opensite(link){
      window.open(link)
    },  

    // Open new window with generated code
    codeGen() {
      if (this.code.length < 10) { return }
      window.open().document.write(this.code)
    },
    routLink(n,a) {
      this.$router.push({
        name: a,
        params: { id: n.id }, 
      });
    },
    logout() {
      this.$firebase.auth().signOut();
      // location.reload(true)
    }, 
    
    viewManifest(data){
      this.mani = data
    },
    viewCode(data){
      this.code = data
    },
    viewService(data){
      this.service = data
    },

    //manifest JSON파일 텍스트로 변환
    // showMani(){
    //   const props = this.manifest.split(',').map((prop) => prop.trim());
    //   props[0] = props[0].substring(1);
    //   props[props.length - 1] = props[props.length - 1].slice(0, -1);
    //   this.mani ='{\n' + props.join(',\n') + '\n}';
    //   console.log(this.mani);
    // }, 
    // 코드 다운로드 함수
    downloadFile(value) {
      if (value.length < 10) { return; }
      let extension, fileName, fileType;
      if (value.startsWith('{')) {
        extension = 'json';
        fileName = 'manifest.json';
        fileType = 'application/json';
      // } else if (value.startsWith('<!DOCTYPE html>') || value.startsWith('<html>')) {
      } else if (value.startsWith('const')) {
        extension = 'js';
        fileName = 'serviceWorkers.js';
        fileType = 'application/javascript';
      } else {
        extension = 'html';
        fileName = 'index.html';
        fileType = 'text/html';
      }
    
      const blob = new Blob([value], { type: fileType });
      const a = document.createElement('a');
      a.download = fileName;
      a.href = URL.createObjectURL(blob);
      a.click();
    },
addLinks() {
  const parser = new DOMParser();
  const doc = parser.parseFromString(this.code, "text/html");
  
  const head = doc.head;
  const body = doc.body;
  
  const manifestLink = document.createElement("link");
  manifestLink.setAttribute("rel", "manifest");
  manifestLink.setAttribute("href", "/manifest.json");
  
  const script = document.createElement("script");
  script.innerHTML = `
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('./serviceWorker.js')
          .then(registration => {
            console.log('Service worker registered:', registration);
          })
          .catch(error => {
            console.log('Service worker registration failed:', error);
          });
      });
    }
  `;
  
  head.appendChild(manifestLink);
  body.appendChild(script);
  
  this.code = doc.documentElement.outerHTML;
},

    //에어테이블 저장
    async saveAirtable(){
      const id = Date.now().toString();
      const regex = /<title>(.*?)<\/title>/; // 정규표현식 패턴
      const match = regex.exec(this.code); // 정규표현식과 문자열 매칭
      let title = 'title'
      if (match) {
        const title0 = match[1]; // 첫 번째 그룹에 매칭된 문자열 추출
        title = title0;
      } else {
        title = 'title';
      }
      const API_KEY = process.env.VUE_APP_AIRTABLE
      const airtableUrl = 'https://api.airtable.com/v0/appi1g0DJSemiNpki/code';
      const headers = {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json'
      };
      const maxLength = 100000; // Airtable에 저장할 수 있는 최대 문자 길이
    const codeLength = this.code.length;
    if (codeLength > maxLength) {
      console.error(`Code length(${codeLength}) exceeds the maximum limit(${maxLength})`);
      return;
    }
      const data = {
        "fields": {
          "ID": id,
          "title": title,
          "code": this.code,
          "Date": this.today,
          "creator":this.creator,
          "uid":this.uid,
          "email":this.email
        }
      };
      console.log(this.$store.state.userData)
    
      try {
        const response = await fetch(airtableUrl, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(data)
        });
        const json = await response.json();
        console.log(json);
        console.log('Successfully saved to Airtable!');
      } catch (error) {
        console.error(error);
      }
    }
  },
  exText(){
    const regex = /<title>(.*?)<\/title>/; // 정규표현식 패턴
    const match = regex.exec(this.code); // 정규표현식과 문자열 매칭
    if (match) {
      const title = match[1]; // 첫 번째 그룹에 매칭된 문자열 추출
      console.log(title);
    } else {
      console.log("Title not found");
    }
  }
};
</script>
<style scoped>
.itemTab{
  background-color: #1576c0;
}
.toolbar{
  background-image: url(@/assets/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
} 
.toolbarH{
  height: 500px;
}
.youtubeCard{ 
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.title {
  text-shadow: 2px 2px 2px gray;
}
.itemRow {
  flex-wrap: nowrap;
  overflow-x: auto;
} 
.smallText {
  text-transform: uppercase;
} 
 
 .robot {
  position: absolute;
  right: 10px;
  top: -40px;
  width: 130px;
}
.bg{
  background-image: url(@/assets/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
} 
.people {
  position: absolute;
  left: 0;
  top: -30px;
  width: 340px;
}
 

@media (max-width: 500px) {
  .smallText {
    font-size: large;
  }
  .menuBox {
    border-radius: 10px;
  }
  .jumboRight {
    display: none;
  } 
}
</style>
